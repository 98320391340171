import { InputGroup, InputLeftAddon, Button, Input, FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import React, { FC } from "react";
import { Control, useController } from "react-hook-form";
import { usePhoneInput, CountrySelector } from "react-international-phone";
import { FormData } from "../form";

interface PhoneNumberInputProps {
    control: Control<FormData, any>
    name: keyof FormData
}

export const PhoneNumberInput :FC<PhoneNumberInputProps> = ({ control, name }) => {
    const {
        field,
        formState: { errors }
    } = useController({
        name,
        control,
        rules: {
            required: {value: true, message: 'telephone is required'},
            pattern: {value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g,  message: "Please Enter valid phone number"}
        }
    })

    const phoneInput = usePhoneInput({
        defaultCountry: 'gb',
        onChange: (data) => {
            field.onChange(data.phone);
        },
    })

    return (
        <>
            <FormControl isInvalid={!!errors.telephone} isRequired className="phoneInput">
                <FormLabel>Telephone</FormLabel>
                <InputGroup id={name}>
                    <InputLeftAddon width='4rem'>
                        <CountrySelector
                            selectedCountry={phoneInput.country.iso2}
                            onSelect={(country) => phoneInput.setCountry(country.iso2)}
                            renderButtonWrapper={({ children, rootProps }) => 
                                <Button {...rootProps} variant={'outline'} px={'4px'} mr={'8px'}>
                                    {children}
                                </Button>
                            }
                        />
                    </InputLeftAddon>
                    
                    <Input
                        type='tel'
                        value={phoneInput.phone}
                        onChange={phoneInput.handlePhoneValueChange}
                        onBlur={field.onBlur}
                        name={name}
                        ref={(el) => phoneInput.inputRef && field.ref(el)}
                    />
                    
                </InputGroup>
                <FormErrorMessage>{errors.telephone?.message}</FormErrorMessage>
            </FormControl>
        </>
    )
};
