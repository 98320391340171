import { SubmitHandler, useForm } from "react-hook-form";
import {
    Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure,
  } from '@chakra-ui/react'
import 'react-international-phone/style.css';
import { PhoneNumberInput } from "./form-controls/phone-input";
import { TextInput } from "./form-controls/text-input";
import { SwitchInput } from "./form-controls/switch-input";
import { TextAreaInput } from "./form-controls/text-area-input";
import { SelectInput } from "./form-controls/select-input";
import { getDatabase, ref, set } from "firebase/database";
import app from "../../../firebase";
import { useEffect } from "react";

export type FormData = {
    firstName: string;
    lastName:string;
    email: string;
    telephone: string;
    hasDietRequirements: boolean;
    dietRequirementDetails: string;
    daysAttending: "friday" | "saturday" | "both" | "notAttending"
    interestedInAccomodation: boolean
}

export const RsvpForm = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { handleSubmit, control, watch, reset, formState} = useForm<FormData>({defaultValues: {dietRequirementDetails: '', hasDietRequirements: false, daysAttending:"both", interestedInAccomodation: false}})

    useEffect(() =>{
        if(formState.isSubmitSuccessful) {
            reset({firstName: '', lastName: '', email: '', telephone: '', dietRequirementDetails: '', hasDietRequirements: false, daysAttending:"both", interestedInAccomodation: false});
        }
    }, [formState.isSubmitSuccessful, reset])

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const db = getDatabase(app);
        await set(ref(db, `attendees/${data.firstName}${data.lastName}`), data);
        onOpen()
    }

    const onModalClose = () => {
        onClose()
    }

    const hasDietRequirements = watch("hasDietRequirements");


    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <TextInput 
                label={'First Name'}
                name={"firstName"}
                control={control}
                rules={{
                    required: {value: true, message: 'First name is required'},
                    maxLength: {value: 25, message: "Max length 25 characters"}
                }} 
            />

            <TextInput 
                label={'Last Name'}
                name={"lastName"}
                control={control}
                rules={{
                    required: {value: true, message: 'Last name is required'},
                    maxLength: {value: 25, message: "Max length 25 characters"}
                }} 
            />

            <TextInput 
                label={'Email'}
                name={"email"}
                control={control}
                rules={{
                    required: { value: true, message: 'Email is required' },
                    pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: "Please Enter valid email address"}
                }} 
            />

            <PhoneNumberInput name={"telephone"} control={control}/>

            <SwitchInput control={control} label={'Do you have any special dietary requirements?'} name={'hasDietRequirements'}/>

            <div className={`fade-in-section ${ hasDietRequirements ? 'is-visible' : ''}`}>
                <TextAreaInput control={control} label={'Please leave any dietary requirement details below'} name={'dietRequirementDetails'}/>
            </div>

            <SelectInput control={control} label={'What days are you attending?'} name={'daysAttending'}>
                <option value='both'>Both Days</option>
                <option value='saturday'>Just Saturday</option>
                <option value='friday'>Just Friday</option>
                <option value='notAttending'>Unfortunately I can not attend</option>
            </SelectInput>

            <SwitchInput control={control} label={'Are you interested in on-site accomodation?'} name={'interestedInAccomodation'}/>
            
        
            <Button colorScheme='blue' onClick={handleSubmit(onSubmit)}>Submit RSVP </Button>

            

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>RSVP submitted</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Thank You, Looking forward to seeing you!
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onModalClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </form>
    )
}