export const RunningOrder = () => {
  return (
    <div>
      <h1>Running Order</h1>
      <div className="section">
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h2>Friday</h2>
          <h3>
            <b>From 4pm</b> - Arrive At the venue and Accomdation Check-In
          </h3>
          <h3>
            <b>5pm</b> - Festivities begin with drinks and Canapés
          </h3>
          <h3>
            <b>7pm</b> - Street Food
          </h3>
          <h3>
            <b>10pm</b> - Last Orders
          </h3>
        </div>
        <div className="container">
          <h2>Saturday</h2>
          <h3>
            <b>12 Noon</b> - Guests Arrive
          </h3>
          <h3>
            <b>2pm</b> - Ceremony in the glass house
          </h3>
          <h3>
            <b>2:30pm</b> - Drinks Reception
          </h3>
          <h3>
            <b>4:30pm</b> - Wedding Breakfast
          </h3>
          <h3>
            <b>8pm</b> - Dancing
          </h3>
          <h3>
            <b>11pm</b> - Last Orders
          </h3>
        </div>
      </div>
    </div>
  );
};
