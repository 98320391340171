import "../../App.css";
import React from "react";
import { Header } from "../../components/header/header";
import { Welcome } from "./section/welcome";
import { TheVenue } from "./section/the-venue";
import { GettingThere } from "./section/getting-there";
import { Accomodation } from "./section/accomodation";
import RSVPLink from "./toRSVP/RSVPLink";
import { Gifts } from "./section/gifts";
import { RunningOrder } from "./section/running-order";

function Root() {
  return (
    <div className="App">
      <Header>
        <div className="title">Steven Fisher</div>
        <div className="title">&</div>
        <div className="title">Freya Felsenstein</div>
        <div className="sub-title">9th & 10th August 2024</div>
        <div className="sub-title">Combermere Abbey</div>
      </Header>
      <Welcome />
      <TheVenue />
      <GettingThere />
      <Accomodation />
      <RunningOrder />
      <Gifts />
      <RSVPLink />

      <div style={{ height: "100px", width: "100%" }}></div>
    </div>
  );
}

export default Root;
