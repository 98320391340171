import { FormControl, FormLabel, FormErrorMessage, Textarea, TextareaProps } from "@chakra-ui/react"
import { FC } from "react"
import { Control, RegisterOptions, useController } from "react-hook-form"
import { FormData } from "../form"

interface Props {
    control: Control<FormData, any>;
    name: keyof FormData;
    label: string;
    rules?: Omit<RegisterOptions<FormData, keyof FormData>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"> | undefined
}

export const TextAreaInput : FC<Props>= ({control, name, label, rules}) => {
    const {
        field,
        formState: { errors }
    } = useController({
        name,
        control,
        rules
    })

    return (
        <FormControl isInvalid={!!errors[name]} isRequired={!!rules?.required}>
            <FormLabel>{label}</FormLabel>
            <Textarea {...field as TextareaProps} />
            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
        </FormControl>
    )
}