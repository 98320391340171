import './imageWithBackground.css'

import { FC } from "react";
import { WithBackground } from './withBackground';

interface Props {
    image: string
    altText?: string
}

export const ImageWithBackground : FC<Props> = ({image, altText}) => {
  return (
    <WithBackground>
      <img className={'Image'} src={image} alt={altText}/>
    </WithBackground>
  );
};
