import { Header } from "../../components/header/header";
import { RsvpForm } from "./rsvp-form/form";

export const RsvpPage = () => {
    return (
        <div className="App">
          <Header>
            <div style={{maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto'}}>
              <h1>RSVP</h1>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div style={{width: '100%', maxWidth: '800px'}}>
                <RsvpForm />
                </div>
              </div>
            </div>
          </Header>
        </div>
      );
}