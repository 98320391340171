import './imageWithBackground.css'

import { FC, PropsWithChildren } from "react";
import Splatter1 from "../../images/Splatter-1.jpg";
import Splatter2 from "../../images/Splatter-2.jpg";
import Splatter3 from "../../images/Splatter-3.jpg";

export const WithBackground : FC<PropsWithChildren> = ({children}) => {
  const randomNumber = Math.floor(Math.random() * (3 - 1 + 1) + 1);
  let Background = Splatter1;
  if (randomNumber === 2) {
    Background = Splatter2;
  } else if (randomNumber === 3) {
    Background = Splatter3;
  }
  return (
    <div
    className="ImageContainer"
      style={{
        backgroundImage: `url(${Background})`,
      }}
    >
       {children}
    </div>
  );
};
