// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBNuQnCw_TUwKR0McW5LYt3tCzcRqrxOgo",
  authDomain: "weddingwebsite-d3802.firebaseapp.com",
  databaseURL: "https://weddingwebsite-d3802-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "weddingwebsite-d3802",
  storageBucket: "weddingwebsite-d3802.appspot.com",
  messagingSenderId: "11315372131",
  appId: "1:11315372131:web:e4388bb37eb1ab4bb59856",
  measurementId: "G-NPR82K13Z0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
getAnalytics(app);

export default app;
