import * as React from 'react';
import PinImage from "../../images/customPin.png";


function Pin() {
  return (
    <img src={PinImage} alt={'wedding location'} style={{height: '100px'}}/>
  );
}

export default React.memo(Pin);