import Map, { MapStyle, Marker, NavigationControl, ScaleControl } from 'react-map-gl';
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

import { getMapStyle } from '../../../components/map/getMapStyle';
import Pin from '../../../components/map/pin';
import { WithBackground } from '../../../components/imageWithBackground/withBackground';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;


export const GettingThere = () => {
  const mapStyle= getMapStyle() as unknown as MapStyle
  return (
    <div className="section">
      <div className="container" style={{display: 'flex', justifyContent: 'center'}}>
          <WithBackground>
            <Map
              mapboxAccessToken="pk.eyJ1Ijoic3RlZWxnZWFyIiwiYSI6ImNscDQ0ZTlpczFnMXoya3M0dzBsc3gwc2kifQ.03_SY3c9K9hIRAzZXIkqtw"
              initialViewState={{
                longitude: -2.611521,
                latitude: 52.993913,
                zoom: 8
              }}
              style={{height: 700, width: 700, maxHeight: '75vh', maxWidth: '100vw'}}
              mapStyle={mapStyle}
            >
              <NavigationControl position="top-left" />
              <ScaleControl />
              <Marker
                longitude={-2.611521}
                latitude={52.993913}
                anchor="bottom"
              >
                <Pin />
              </Marker>
            </Map>
          </WithBackground>
      </div>
      <div className="container">
        <h1>Getting There</h1>
        <p>Combermere Abbey is conveniently situated just a brief 10-minute drive outside the charming town of Whitchurch in the North West.</p>
        <p>If you're travelling from Manchester City Centre or arriving at Manchester Airport, the journey to the Abbey is a scenic one through picturesque landscapes, taking approximately an hour by car.  There is free parking on the grounds too.</p>
        <p>Local taxi companies are at your service for pre-arranged pick-ups and drop-offs in the surrounding area. To make your travel arrangements more convenient, it's advisable to contact them in advance. Please note that cash will be needed, as Uber services are not operational in this rural setting.</p>
        <p>For those arriving by public transport, the nearest train station is located in Whitchurch. If you plan to use public transportation, we recommend checking the train schedules in advance to ensure a smooth journey.</p>
        <p>The complete address for your reference is:<br/>
          Combermere,<br/>
          Whitchurch,<br/>
          SY13 4AJ.<br/>
        </p>
        <p>Should you have any questions or need further assistance in planning your transportation, feel free to reach out to us. </p>
      </div>
      
    </div>
  );
};
