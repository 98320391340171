import './App.css';

import React from 'react';
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import Root from './pages/root/root';
import { RsvpPage } from './pages/rsvp/rsvp';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
  },
  {
    path: "/rsvp",
    element: <RsvpPage/>
  }
]);

function App() {
  
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
