import Combermere from "../../../images/combermere.jpeg";
import { ImageWithBackground } from "../../../components/imageWithBackground/imageWithBackground";

export const TheVenue = () => {
  return (
    <div className="section">
      
      <div className="container">
        <h1>The Venue</h1>
        <p>Nestled amidst acres of rolling parkland, Combermere Abbey is a haven of natural beauty and ancient woodlands, setting the stage for an unforgettable celebration.</p>
        <p>Our ceremony will take place in the embrace of the Edwardian glasshouse—a luminous sanctuary nestled within a maze of fruit trees. Picture a scene of serenity and elegance, where the soft glow of natural light bathes the space, creating an intimate and enchanting atmosphere.</p>
        <p>As you explore the grounds, you'll discover the Pavilion, standing proudly in the heart of the Middle-Walled Garden. This charming structure will serve as the central hub of our wedding celebration. Yet, the magic of Combermere extends beyond, inviting you to wander through the exquisite Victorian walled gardens.</p>
        <p>The venue's allure is further enhanced by the commanding presence of the Gothic architecture of the north-wing, gracefully overlooking the shores of Combermere. From this vantage point, you'll be treated to enchanting views across the tranquil water, providing a picturesque backdrop to our celebration.</p>
        <p>As you join us in this idyllic setting, we hope you'll take the time to stroll through the historic woodlands, enjoying the carefully manicured gardens, or simply take in the scenic views.</p>
      </div>
      <div className="container">
        <ImageWithBackground image={Combermere} />
      </div>
    </div>
  );
};
