import { Button } from "@chakra-ui/react";
import Sideman from "../../../images/sideman.jpg";
import React from "react";
import { ImageWithBackground } from "../../../components/imageWithBackground/imageWithBackground";

export const Gifts = () => {
  return (
    <>
      <div className="section">
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ImageWithBackground image={Sideman} />
        </div>
        <div className="container">
          <h1>Wedding Gifts</h1>
          <p>
            We've been asked about gifts, and we want to make it clear that your
            presence at our wedding is the greatest gift of all.{" "}
          </p>
          <p>
            However, if you would like to contribute in another way, we have set
            up a honeymoon fund. Any donations towards our honeymoon would be
            incredibly appreciated and cherished. Your generosity will help us
            create unforgettable memories as we begin our married life together.
          </p>
          <p>Steven & Freya</p>
        </div>
      </div>
      <Button
        colorScheme="blue"
        onClick={() => {
          window.location.href =
            "https://giftlist.kuoni.co.uk/gift-contribute?ref=44555";
        }}
      >
        Contribute to our Honeymoon
      </Button>
    </>
  );
};
