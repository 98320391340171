import AccomodationImg from "../../../images/combermere-accomodation.jpg";
import { ImageWithBackground } from "../../../components/imageWithBackground/imageWithBackground";

export const Accomodation = () => {
  return (
    <div className="section">
      
      <div className="container">
        <h1>Accommodation</h1>
        <p>Immerse yourself in the atmosphere of Combermere Abbey by choosing to stay in one of our beautiful cottages nestled on the grounds. These lovingly renewed and revitalised cottages, once Victorian stables that graced the estate, offer a unique blend of historic charm and modern comfort. Each cottage is self-catering, providing you with the flexibility to create your own retreat within the serene surroundings.</p>
        <p>The cottages vary in size, accommodating different preferences and needs, and can be reserved for £204 per room per night. To enhance your stay, a delightful welcome basket awaits, filled with breakfast supplies and other yummy surprises to start your day with joy.</p>
        <p>In total, our cottages can accommodate 49 people. If the idea of waking up on the grounds of Combermere Abbey appeals to you, kindly indicate your interest in staying when you RSVP. As the cottages vary in size, we'll allocate rooms based on need and make every effort to keep family and friend groups together for a more intimate experience.</p>
        <p>Recognizing that space on the grounds is limited, we understand that some guests may prefer alternative accommodation. In such cases, you'll find a variety of options available in the nearby area. The Macdonald Hill Valley hotel, Premier Inn Nantwich, and a myriad of charming Bed and Breakfasts or Airbnbs offer diverse choices to suit your preferences. Please feel free to explore these alternatives and select the option that best compliments your stay.</p>
        <p>Whether you choose to be part of the on-site experience in our cosy cottages or opt for accommodation nearby, we look forward to having you share in the joy of our wedding celebration.</p>
        <p>If you have any specific accommodation preferences or need assistance in making arrangements, please don't hesitate to reach out.</p>
      </div>
      <div className="container">
        <ImageWithBackground image={AccomodationImg} />
      </div>
    </div>
  );
};
