import { FormControl, FormLabel, FormErrorMessage, Select, SelectProps } from "@chakra-ui/react"
import { FC, PropsWithChildren } from "react"
import { Control, RegisterOptions, useController } from "react-hook-form"
import { FormData } from "../form"

interface Props {
    control: Control<FormData, any>;
    name: keyof FormData;
    label: string;
    rules?: Omit<RegisterOptions<FormData, keyof FormData>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"> | undefined
}

export const SelectInput : FC<PropsWithChildren<Props>>= ({control, name, label, rules, children}) => {
    const {
        field,
        formState: { errors }
    } = useController({
        name,
        control,
        rules
    })

    return (
        <FormControl isInvalid={!!errors[name]} isRequired={!!rules?.required}>
            <FormLabel>{label}</FormLabel>
            <Select {...field as SelectProps}>{children}</Select>
            <FormErrorMessage>{errors[name]?.message}</FormErrorMessage>
        </FormControl>
    )
}