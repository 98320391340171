import { FormControl, FormLabel, Switch, SwitchProps } from "@chakra-ui/react"
import { FC } from "react"
import { Control, useController } from "react-hook-form"
import { FormData } from "../form"

interface Props {
    control: Control<FormData, any>;
    name: keyof FormData;
    label: string;
}

export const SwitchInput : FC<Props>= ({control, name, label}) => {
    const {
        field,
    } = useController({
        name,
        control
    })

    return (
        <FormControl display='flex' alignItems='center'>
            <FormLabel>
            {label}
            </FormLabel>
            <Switch {...field as SwitchProps} />
      </FormControl>
    )
}