import FandCWedding from "../../../images/FCWedding.jpg";
import { ImageWithBackground } from "../../../components/imageWithBackground/imageWithBackground";

export const Welcome = () => {
  return (
    <div className="section">
      <div className="container">
        <ImageWithBackground image={FandCWedding} />
      </div>
      <div className="container">
        <h1>You're Invited to the wedding of Steven Fisher & Freya Felsenstein</h1>
        <p>
          We would be honoured if you would join us in celebrating our wedding at the enchanting Combermere Abbey, nestled in the picturesque Cheshire countryside, on the 9th and 10th of August 2024.
        </p>
        
        <p>
        Your presence means the world to us, and to ensure we can share these precious moments with all our cherished friends and family, we've decided to extend the festivities into a two-day extravaganza! 
        </p>

        <p>
          On Friday, the 9th of August, we invite you to join us for a more relaxed and intimate gathering amid the stunning gardens of the venue. It will be a delightful garden party, filled with laughter and good company. This will be the perfect opportunity for everyone to ease into the celebratory weekend.
        </p>

        <p>Then, on Saturday, the 10th of August, the main event will unfold. We're excited to witness the exchange of vows and celebrate, surrounded by the warmth and support of our closest friends and family. The day promises to be filled with love, laughter, and unforgettable moments.</p>
        <p>We want to emphasise that everyone is welcome to join us for both days, as your presence will only enhance the joy of our special weekend. Your love and friendship have played an integral part in our journey, and we can't wait to create lasting memories with you during this extraordinary celebration.</p>
        <p>Please mark your calendars, save the dates, and share in our joy as we embark on this beautiful journey together. We sincerely hope you can join us for a weekend that promises not only to be special for us but also for each and every one of you.</p>
        <p>Looking forward to sharing these magical moments with you!</p>
      </div>
    </div>
  );
};
