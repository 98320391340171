import { Button } from "@chakra-ui/react"
import React from "react"
import { useNavigate } from "react-router-dom";
import './RSVPLink.css'

const RSVPLink = () => {
    const navigate = useNavigate();
    return (
        <div className={'rsvpLink'}>
            <div className={'innerRsvpLink'}>
                <Button colorScheme='blue' onClick={() => {navigate('/rsvp')}}>Click here to RSVP</Button>
            </div>
        </div>
    )
}

export default RSVPLink