import "./header.css";

import LandscapeBackground from "../../images/DustyBlue-3-landscape.jpg";
import PortraitBackground from "../../images/DustyBlue-3-portrait.jpg"
import { useWindowSize } from "../../useScreenSize";
import { FC, PropsWithChildren } from "react";

export const Header : FC<PropsWithChildren>= ({children}) => {
  const {innerWidth} = useWindowSize();

  const Background = innerWidth < 1000 ? PortraitBackground : LandscapeBackground
  return (
    <header>
      <div className="header" style={{ backgroundImage: `url(${Background})`}}>
        <div className="innerHeader">
          {children}
        </div>
      </div>
    </header>
  );
};
